// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// This is the package.json file in the 'Customer' project
import pkg from '../../package.json';

// This is the package.json file in the parent 'Noctem Web' folder
// import pkg from '../../../../../package.json';
const buildHash = pkg.buildHash || pkg.version || 'N/A';
const appVersion = pkg.version || 'N/A';

export const environment = {
  buildHash: buildHash,
  appVersion: appVersion,
  production: false,
  organization: 'noctem',
  token: '/O3knwszBEuwjhdE1fqXaw==3Jiiicvui0yD1tugMvWwgg==',
  appHost: 'https://dev-portal.noctemhealth.com/api', //this is for ionic build
  appSocketHost: 'https://dev-portal.noctemhealth.com',
  //appHost: '',
  //appSocketHost: '',
  appSocketPath: '/noctem-socket/',
  fitbitLink: 'https://www.fitbit.com/oauth2/authorize?response_type=code&client_id=22BWNX&redirect_uri=https%3A%2F%2Fdev-portal.noctemhealth.com%2Fapi%2Fuser%2FwearableCallback%2Ffitbit&scope=activity%20nutrition%20heartrate%20location%20nutrition%20profile%20settings%20sleep%20social%20weight&expires_in=31536000&prompt=login&state='
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
